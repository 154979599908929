import {Controller} from "@hotwired/stimulus"
import {default as confetti} from 'canvas-confetti';

export default class extends Controller {

    static values = {
        autoplay: Boolean,
        autoplayDuration: Number,
        sound: String,
    };

    connect() {
        super.connect();

        if (this.autoplayValue) {
            this.playFireworks(this.autoplayDurationValue);
        }
    }

    fire() {
        if (this.soundValue) {
            new Audio(this.soundValue).play();
        }

        var count = 200;
        var defaults = {
            origin: {y: 0.7}
        };

        function fire(particleRatio, opts) {
            confetti(Object.assign({}, defaults, opts, {
                particleCount: Math.floor(count * particleRatio)
            }));
        }

        fire(0.25, {
            spread: 26,
            startVelocity: 55,
        });
        fire(0.2, {
            spread: 60,
        });
        fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
        });
        fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
        });
        fire(0.1, {
            spread: 120,
            startVelocity: 45,
        });
    }

    playFireworks(durationSecs) {
        const durationMillis = durationSecs * 1000;
        const animationEnd = Date.now() + durationMillis;
        const defaults = {startVelocity: 40, spread: 360, ticks: 60, zIndex: 0};

        const randomInRange = this.randomInRange;

        const interval = setInterval(function () {
            const timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) {
                return clearInterval(interval);
            }

            const particleCount = 50 * (timeLeft / durationMillis);
            // since particles fall down, start a bit higher than random
            confetti(Object.assign({}, defaults, {
                particleCount,
                origin: {x: randomInRange(0.1, 0.3), y: Math.random() - 0.2}
            }));
            confetti(Object.assign({}, defaults, {
                particleCount,
                origin: {x: randomInRange(0.7, 0.9), y: Math.random() - 0.2}
            }));
        }, 400);
    }

    randomInRange(min, max) {
        return Math.random() * (max - min) + min;
    }
}