import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static get targets() {
        return [
            "badge",
            "toggle"
        ]
    }

    static values = {
        labelActive: String,
        labelInActive: String,
        urlActive: String,
        urlInActive: String,
    };

    async toggle() {
        const isChecked = this.toggleTarget.checked;

        if (isChecked) {
            await fetch(this.urlActiveValue);
            this.badgeTarget.innerHTML = this.labelActiveValue;
        } else {
            await fetch(this.urlInActiveValue);
            this.badgeTarget.innerHTML = this.labelInActiveValue;
        }
    }
}