import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static get targets() {
        return [
            "form",
        ]
    }

    static values = {
        url: String,
    };

    async submit() {
        await fetch(this.formTarget.action, {
            method: 'post',
            body: new FormData(this.formTarget)
        });
    }
}