import {Controller} from "@hotwired/stimulus"
import {cookieHasValue, setCookie} from "../lib/cookie"

const COOKIE_CONSENT_VIEWED = 'COOKIE_CONSENT_VIEWED';

export default class extends Controller {

    static get targets() {
        return [
            "accept",
            "banner"
        ]
    }

    accept() {
        setCookie({
            name: COOKIE_CONSENT_VIEWED,
            expires: this.expires(),
            value: 'true'
        });
        this.bannerTarget.style.visibility = 'hidden';
        this.bannerTarget.style.height = 0;
    }

    connect() {
        if (cookieHasValue(COOKIE_CONSENT_VIEWED, 'true')) {
            this.bannerTarget.style.visibility = 'hidden';
            this.bannerTarget.style.height = 0;
        } else {
            this.bannerTarget.style.visibility = 'visible';
        }
    }

    expires() {
        const now = new Date();
        now.setFullYear(now.getFullYear() + 1);

        return now;
    }
}