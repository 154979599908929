import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static get targets() {
        return [
            "blob",
            "input",
        ]
    }

    connect() {
        this.onChange = this.onChange.bind(this)
    }

    onChange() {
        const file = this.inputTarget.files && this.inputTarget.files[0]
            ? this.inputTarget.files[0]
            : undefined;

        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                const base64 = reader.result.split(',')
                this.blobTarget.value = base64[1];
            };

            reader.readAsDataURL(file);
        }
    }
}