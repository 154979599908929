import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static get targets() {
        return [
            "input",
            "option",
            "outputDescription",
            "outputValue",
        ]
    }

    onChange() {
        const value = parseFloat(this.inputTarget.value).toFixed(1);
        if (value) {
            const option = this.optionTargets.find(it => it.value === value);
            const description = option
                ? option.dataset.playingAbilityDescription
                : "";

            this.outputDescriptionTarget.innerHTML = description;
            this.outputValueTarget.innerHTML = value;
        }
    }
}