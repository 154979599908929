import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static get targets() {
        return [
            "input",
            "element",
        ]
    }

    onChange() {
        const query = this.inputTarget.value.toLowerCase().normalize("NFKD");
        this.elementTargets.forEach((element, index) => {
            const filterKey = element.dataset.filterKey.toLowerCase().normalize("NFKD");
            element.classList.toggle("d-none", !filterKey.includes( query ) )
        });
    }
}