import * as bootstrap from 'bootstrap';
import * as Sentry from "@sentry/browser";
import { Chart } from 'chart.js/auto'
import {navigator} from "@hotwired/turbo";
import {Integrations} from "@sentry/tracing";
import Lightbox from 'stimulus-lightbox'

import {Application} from "@hotwired/stimulus";
import {default as AdminUserManagementController} from './controllers/admin-user-management-controller';
import {default as AutocompleteController} from './controllers/autocomplete-controller';
import {default as AvatarUploadController} from './controllers/avatar-upload-controller';
import {default as ConfettiController} from './controllers/confetti-controller';
import {default as CookieConsentController} from './controllers/cookie-consent-controller';
import {default as DrawEditController} from './controllers/draw-edit-controller';
import {default as HeaderResizeController} from './controllers/header-resize-controller';
import {default as HeaderToHeadController} from './controllers/head-to-head-controller';
import {default as ImageUploadController} from './controllers/image-upload-controller';
import {default as PlayerAutocompleteController} from './controllers/player-autocomplete-controller';
import {default as PlayingAbilityController} from './controllers/playing-ability-controller';
import {default as TableFilterController} from './controllers/table-filter-controller';

const application = Application.start();
const profile = document.querySelector('meta[name="ApplicationProfile"]');
application.register("admin-user-management", AdminUserManagementController);
application.register("autocomplete", AutocompleteController);
application.register("avatar-upload", AvatarUploadController);
application.register("confetti", ConfettiController);
application.register("cookie-consent", CookieConsentController);
application.register("draw-edit", DrawEditController);
application.register("head-to-head", HeaderToHeadController);
application.register("header-resize", HeaderResizeController);
application.register("image-upload", ImageUploadController);
application.register("player-autocomplete", PlayerAutocompleteController);
application.register("playing-ability", PlayingAbilityController);
application.register("table-filter", TableFilterController);
application.register('lightbox', Lightbox);

window.chart = Chart;

if (profile !== null && profile.content !== null && profile.content === 'dev') {
} else {
    Sentry.init({
        dsn: "https://8b2446049dcd42e09078a4c847654660@o226940.ingest.sentry.io/6178573",
        integrations: [new Integrations.BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

function initPopover() {
    const elements = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    elements.map((element) => {
        return new bootstrap.Popover(element, {
            trigger: 'click focus hover'
        });
    });
}

function initTooltips() {
    const elements = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    elements.map((element) => {
        return new bootstrap.Tooltip(element, {
            trigger: 'click focus hover'
        });
    });
}

document.addEventListener("turbo:frame-load", () => {
    initPopover();
    initTooltips();
});

document.addEventListener("turbo:frame-render", (event) => {
    const url = event.detail.fetchResponse.response.headers.get('X-Navigator-History-Url');
    if (url) {
        const current = new URL(window.location.href);
        const target = new URL(`${current.origin}${url}`);

        navigator.history.push(target);
    }
});

document.addEventListener("DOMContentLoaded", function(event) {
    initPopover();
    initTooltips();
});