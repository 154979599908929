import {default as BaseAutocompleteController} from '../lib/autocomplete-controller'

export default class extends BaseAutocompleteController {

    commit(selected) {
        super.select(selected);

        const name = this.inputTarget.getAttribute("name");
        const playerId = selected.getAttribute("data-autocomplete-value");
        const url = this.url(name, playerId)

        if (url && window.location) {
            window.location.replace(url);
        }
    }

    url(name, playerId) {
        const parts = window.location.pathname
            .split('/');

        if (name === 'player_one') {
            parts[2] = playerId;
        } else if (name === 'player_two') {
            parts[3] = playerId;
        }

        return parts.join('/');
    }
}