import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static get targets() {
        return [
            "element",
            "spacer"
        ]
    }

    resizeObserver;

    adjustSpacer(spacer, elements) {
        let height = elements.reduce((acc, it) => {
            acc += it.offsetHeight;

            return acc;
        }, 0);

        spacer.style.marginTop = `${height}px`;
    }

    elementTargetConnected(target) {
        if (this.hasSpacerTarget) {
            this.resizeObserver = new ResizeObserver(
                () => this.adjustSpacer(this.spacerTarget, this.elementTargets)
            );

            this.resizeObserver.observe(target);
        }
    }

    elementTargetDisconnected(target) {
        if (this.resizeObserver) {
            this.resizeObserver.unobserve(target);
        }
    }
}